// overrides bulma variables
// import at start before importing bulma components

$blue: #3F77F8;
$green: #2DCE89;
$red: #E12047FF;
$cyan: #4CDFE8;
$orange: #FF6647;
$black: #1e1d1d;
$dark: #131618;
$dark-ter: #1d2123;
$white: #FFFFFF;
$white-ter: #f2f6fc;
$grey: #E2E2E2;
$grey-dark: darken($grey, 10%);
$grey-darker: darken($grey, 30%);
$grey-lighter: hsl(0, 0%, 86%);

$primary: $blue;
$success: $green;
$danger: $red;
$info: $cyan;
$warning: $orange;
$link: $blue;
$light: $white-ter;
$text: $black;

$menu-item-color: var(--menu-text-color);
$menu-item-hover-background-color: var(--menu-hover-highlight-color);
$menu-item-hover-color: var(--menu-hover-text-color);
$menu-item-active-background-color: $light;
$menu-item-active-color: var(--menu-text-color-active);
$menu-item-active-color: var(--menu-item-active-color);
$menu-item-active-background-color: var(--menu-item-active-background);

$input-background-color: var(--input-background-color);
$input-border-color: var(--input-border-color);

$table-cell-padding: 1em 0.75em;
$table-cell-border: 1px solid var(--table-border-color);
$table-head-background-color: var(--table-head-background-color);
$table-head-cell-color: var(--table-head-cell-color);
$table-background-color: var(--table-background-color);

$dropdown-divider-background-color: var(--dropdown-divider-color);
