.table-responsive {
  overflow: auto;
}

.table-no-data {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  text-align: center;
}

.table-controls {
  margin-bottom: 1em;

  .table-searchbox {
    flex-grow: 1;
    max-width: 400px;
  }

  .table-custom-controls {
    display: flex;
    flex-direction: row;

    .table-custom-control--item {
      margin-left: 1em;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.table-pagination-controls-container {
  width: 100%;
  overflow: auto;

  .table-pagination-stats {
    float: left;
    color: black;
    font-weight: 400;
    font-size: 0.9rem;
  }

  .table-pagination-size-select {
    float: left;

    select {
      height: auto;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 1rem;
      padding-right: 2em;
    }
  }

  .table-pagination-page-control {
    float: right;

    .chevron-icons {
      background: none;
      border: none;
      cursor: pointer;
    }

    .page-input-container {
      display: inline-block;
      margin: 0 5px 0 5px;

      .page-input {
        display: inline-block;
        width: 3em;
        padding: 0 5px;
        height: min-content;
      }
    }
  }
}

body.dark {
  .chevron-icons {
    color: white;
  }

  thead {
    th {
      color: #3F77F8 !important;
    }
  }
}

thead {
  th {
    font-weight: 600;
    color: white !important;
  }
}
