@import "../../styles/theme";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/components/menu";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/helpers/spacing";
@import "bulma/sass/components/dropdown";

@import "../../assets//bulma-overrides/bulmaOverrides";

$sidebar-edge-spacing: .5rem;
$sidebar-top-extra: 0.8rem;
$sidebar-header-height: 3.75rem;
$sidebar-footer-height: 2.5rem;


.sidebar {
  width: 280px;
  height: 100%;
  overflow: hidden !important;
  position: fixed;
  left: 2px;
  z-index: 6;
  background-color: var(--sidebar-color);
  padding: 1em 1em;
  // margin: 0em 1em 1em 1em;
  margin: 0em 0em 0em 0.5em;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    div ul li ul {
      margin-top: 0px !important;
    }

    .link-text {

      &:hover {
        color: $primary;
      }
    }
  }

  @media (max-width:780px) {
    width: 97%;
    height: 5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow: hidden;
    justify-content: unset;
  }

  &.full-height {
    height: 100% !important;
  }

  .mobile-extra {
    padding-left: $sidebar-edge-spacing;
    display: none;

    .hamburger {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    @media (max-width:780px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .sidebar-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    @media (max-width:780px) {
      display: none;
    }

    .hamburger {
      width: 40%;
      height: auto;
    }

    img {
      width: 100px;
      height: auto;
    }

    p {
      color: var(--text);
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .sidebar-items {
    height: calc(100% - $sidebar-header-height - $sidebar-footer-height);
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    z-index: 2;
    margin-top: 1em;

    .menu-list {
      li {
        margin-top: 1.25em;
        margin-bottom: 1.25em;
        display: flex;
        align-items: center;
  
        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          font-size: 14px;
          transition: background 0.3s;
          width: 100%;
  
          svg {
            flex-shrink: 0;
          }
          // show scrollbar only on hover
          &:hover {
            transition: ease-in-out 250ms;
            scrollbar-width: thin;
            scrollbar-color: $grey $white;
          }
        }
      }
    }

    
  }

}