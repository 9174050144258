.admin-stats-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 1em;

  .stat-card {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width:1100px){
  .admin-stats-container {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.8em;
  }
}

@media (max-width:400px){
  .admin-stats-container {
    grid-template-columns: repeat(1, 1fr);
  }
}