@import "../../styles/theme";
@import "../../styles/customFonts";

$table-head-cell-color: $primary;
$table-color: $black;

@import "bulma/sass/utilities/all";
@import "bulma/sass/elements/table";
@import "bulma/sass/base/all";

.csv-upload {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .csv-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-height: 40em;
        min-height: 8em;
        border: dashed 2px $grey-darker;
        border-radius: 12px;
        cursor: pointer;

        img {
            width: 100px;
            height: 100px;
            filter: invert(100%) sepia(1%) saturate(1230%) hue-rotate(326deg) brightness(111%) contrast(77%);
        }

        p {
            font: normal 1.4rem $primary-font;
            color: $grey-darker;
            margin: 0;
            text-align: center;

            &.csv-filename {
                font-size: 1rem;
            }
        }

        input {
            display: none;
        }
    }

    .column-selection-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2em;

        select {
            width: 250px;
        }
    }
}