.modal-card {
  position: relative;
  background-color: var(--modal-background-color);

  .custom-modal-close {
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--modal-close-color);
  }
}
