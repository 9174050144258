// overrides bulma variables
// import at start before importing bulma components

$blue: #3F77F8;
$green: #2DCE89;
$red: #E12047FF;
$cyan: #4CDFE8;
$orange: #FF6647;
$black: #000000;
$white: #FFFFFF;
$white-ter: #FAFAFA;
$grey: #E2E2E2;
$grey-dark: darken($grey, 10%);
$grey-darker: darken($grey, 30%);

$primary: $blue;
$success: $green;
$danger: $red;
$info: $cyan;
$warning: $orange;
$light: $white-ter;
$text: $black;

$menu-item-active-background-color: $light;
$menu-item-active-color: $primary;
