@import "../../styles/theme";
@import "../../styles/customFonts";

$table-head-cell-color: $primary;
$table-color: $black;

@import "bulma/sass/utilities/all";
@import "bulma/sass/elements/table";
@import "bulma/sass/base/all";

body.dark {
    .box {
        background-color: #1d2123;
    }

    tbody {
        color: $white;
    }

    h1 {
        color: $white;
    }

    p {
        color: $white;
    }
}

.AddDomainsToIgnoreSection {
    margin-top: 20px;
    padding: 0 !important;
}

.container{
    width: 70vw;
}