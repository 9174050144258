.website-content-plan-container {
  width: 100%;

  .content-plan-cards-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1em;
  }

  .website-info {
    table, tr, td {
      border: none !important;
    }

    .no-break {
      white-space: nowrap;
    }
  }
}
