@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.spinner-spin {
  animation: spin 1500ms linear 0ms infinite;
}

.icon-white {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.icon-primary {
  filter: invert(35%) sepia(69%) saturate(1914%) hue-rotate(211deg) brightness(101%) contrast(94%);
}

.icon-danger {
  filter: invert(25%) sepia(29%) saturate(7178%) hue-rotate(333deg) brightness(88%) contrast(100%);
}

.icon-grey {
  filter: invert(56%) sepia(0%) saturate(528%) hue-rotate(138deg) brightness(85%) contrast(88%);
}

.icon-cursor-pointer {
  cursor: pointer;
}
