@import "./styles/theme";
@import "./styles/customFonts";
@import "bulma";
@import "bulma-switch";

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  background-color: var(--background-color);
  color: var(--text) !important;
}

body {
  --primary: #{$primary};
  --success: #{$success};
  --danger: #{$danger};
  --info: #{$info};
  --warning: #{$warning};
  --light: #{$light};
  --dark: #{$dark};
  --dark-ter: #{$dark-ter};

  --text: #{$black};

  --background-color: #{$white-ter};

  --sidebar-color: #{$white};
  --menu-text-color: #{$black};
  --menu-hover-highlight-color: #{$grey};
  --menu-hover-text-color: #{$black};
  --menu-text-color-active: #{$primary};
  --menu-item-active-background: #f3f9fd;
  --menu-item-active-color: #{$link};

  --input-background-color: #{$white};
  --input-border-color: #{$grey-lighter};
  --input-placeholder-text-color: #{$grey-darker};

  --card-background-color: #{$white};

  --modal-background-color: #{$white};
  --modal-close-color: #{$black};

  --table-head-background-color: #{$primary};
  --table-head-cell-color: #{$white};
  --table-background-color: #{$white};
  --table-border-color: #{$grey};

  --dropdown-divider-color: hsl(0, 0%, 93%);
}

body.dark {
  --text: #{$white-ter};

  --background-color: #{$dark};

  --sidebar-color: #{$dark-ter};
  --menu-text-color: #{$white-ter};
  --menu-hover-highlight-color: #{$dark};
  --menu-hover-text-color: #{$white};
  --menu-text-color-active: #{$primary};
  --menu-item-active-background: #{$dark};
  --menu-item-active-color: #{$link};

  --input-background-color: #{$dark};
  --input-border-color: #{$grey-darker};
  --input-placeholder-text-color: #{$grey-darker};

  --card-background-color: #{$dark-ter};

  --modal-background-color: #{$dark};
  --modal-close-color: #{$white};

  --table-head-background-color: #{$dark-ter};
  --table-head-cell-color: #{$white};
  --table-background-color: #{$dark-ter};
  --table-border-color: #353333;

  --dropdown-divider-color: hsl(0, 0%, 18.4%);
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1em;

  .auth-card-content {
    margin: 2em 3em;

    .auth-card-logo {
      display: inherit;
      margin: auto;
      width: 128px;
      height: auto;
    }
  }
}


.main-container {
  // display: grid;
  // grid-template-columns: 300px auto;
  // min-height: 100vh;

  width: 100%;
  min-height: 100vh;
  // display: inline-flex;
  padding-left: 19.3rem;
  box-sizing: border-box;

  .workspace-container {
    margin: 1em 1em 1em 0.5em;
    padding-top: 1em;
  }
}

@media (max-width:780px){
  .main-container {
    // grid-template-columns: 0;
    padding-left: 0rem !important;
  }
  .pages-container{
    margin-top: 5em;
  }
}
