@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;700&family=Nunito:wght@400;700&display=swap');

$primary-font: "Nunito", sans-serif;
$secondary-font: "Archivo", sans-serif;

.primary-font {
  font-family: $primary-font;
}

.secondary-font {
  font-family: $secondary-font;
}
