@import "../../styles/theme";
@import "../../styles/customFonts";

$table-head-cell-color: $primary;
$table-color: $black;

@import "bulma/sass/utilities/all";
@import "bulma/sass/elements/table";
@import "bulma/sass/base/all";

// Adds extra styling to bulma table
// Use this in a <div> wrapping <table> element containing bulma's .table class
.abun-table-responsive {
  overflow: auto;
}

.abun-table-container {
  margin-bottom: 0.5rem;

  &.abun-table-container--fullwidth {
    width: 100%;
  }

  .abun-table-button-container {
    margin-bottom: 0.5rem;
    
    button {
      &:nth-child(n) {
        margin-left: .5rem;
        margin-top: 5px;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  thead input[type="checkbox"] {
    transform: scale(1.5);
  }

  .abun-table-search-input {
    max-width: 25em;
    margin-bottom: 25px;
    float: right;
  }

  .abun-table-no-data {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: $grey-light;
    text-align: center;
  }

  .abun-table-pagination-controls-container {
    color: var(--text);
    width: 100%;
    overflow: auto;

    .abun-table-pagination-stats {
      float: left;
      color: var(--text);
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 0.9rem;
    }

    .abun-table-pagination-size-select {
      float: left;

      select {
        height: auto;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 1rem;
        padding-right: 2em;
      }
    }

    .abun-table-pagination-page-control {
      float: right;

      .chevron-icons {
        background: none;
        border: none;
        cursor: pointer;
      }

      .page-input-container {
        display: inline-block;
        margin: 0 5px 0 5px;

        .page-input {
          display: inline-block;
          width: 3em;
          padding: 0 5px;
          height: min-content;
        }
      }

      .page-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 2em;
        height: 2em;
        border-radius: 100%;
        border: 2px solid $primary;
        background-color: $white;
        color: $primary;
        font-family: $secondary-font;
        font-size: 0.9rem;
        font-weight: 700;
        text-align: center;
        margin: 0 5px 0 5px;
        cursor: pointer;

        &:hover {
          background-color: $primary;
          color: $white;
        }

        &.page-active {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }

  table {
    border-bottom: 2px solid hsl(0, 0%, 86%);
  }

  th {
    font-family: $secondary-font;
    font-size: 1.1rem;
    color: white !important;
  }

  td {
    max-width: 400px;
  }

}

.article-table-button-same-width {
  width: 120px;
}

button {
  &:focus {
    outline: none;
  }
}

button:hover {
  outline: none;
  cursor: pointer;
}

.page-loading-container {
  width: 100%;
  // margin-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
