.custom-paragraph {
    font-size: 1rem; /* Adjust font size */
    margin-bottom: 0.5rem; /* Spacing between paragraphs */
    line-height: 1.5; /* Better readability */
    color: #4a4a4a; /* Neutral text color */
}

.custom-strong {
    font-weight: bold; /* Ensures bold text */
    color: #363636; /* Darker color for emphasis */
    padding-right: 5px;
}

.uppercase {
    text-transform: uppercase;
}

/* Add to your CSS file or in a styled-components block */
.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: #fff;
    color: black;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 0%; /* Position above the text */
    left: 142%;
    margin-left: -60px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  
.view_table{
    padding-bottom: 20px;
}