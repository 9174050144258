.article-editor-page-editor-container {
    width: 100%;
}

.reset-font-style {
    font-family: initial;

    * {
        font-family: unset;
    }
}