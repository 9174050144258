.task-log-container {
  width: 100%;

  .task-info {
    table, tr, td {
      border: none !important;
    }

    .no-break {
      white-space: nowrap;
    }
  }

  .log-list {
    display: flex;
    flex-direction: column;

    .log-list--item {
      display: grid;
      grid-template-columns: 120px 200px auto;
      margin: 0.8em 0;

      .tag-width {
        max-width: 80px;
      }

      p {
        margin: 0;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
